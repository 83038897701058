import React from "react"
import { Helmet } from "react-helmet"

export default props => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="robots" content="noindex" />
      <title>MindCode</title>
    </Helmet>
    <h1>{props.title}</h1>
  </>
)
